<template>
    <div class="info">
        <top-bar :title="!submitData.id?'新增':edit?'修改':'查看'" :left="true"></top-bar>
        <!-- 发放日期 -->
        <van-popup v-model="acyeterionTimeShow" position="bottom" >
            <van-datetime-picker v-model="submitData.acyeterionTime" type="datetime" title="请选择" :formatter="formatDate"
                                    @cancel="acyeterionTimeShow = false" @confirm="acyeterionTimeConfirm"/>
        </van-popup>
        <!-- 所在小区 -->
        <van-popup v-model="subareaShow" position="bottom" >
            <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name"
                        @confirm="subareaConfirm" @cancel="subareaShow = false"/>
        </van-popup>
        <van-cell-group>
            <van-cell>
                <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">避孕药领取详情</span>
                </template>
            </van-cell>
            <van-cell title="领取人">
                <van-field clearable clear-trigger="always" v-model="submitData.name" placeholder="请输入" :readonly="!edit"/>
            </van-cell>
            <van-cell title="所在小区" :value="selectMsg.subareaStr" @click="subareaShow = edit" :clickable="edit"
                        :value-class="{'value-common':selectMsg.subareaStr==='请选择'}" :is-link="edit" />
            <van-cell title="联系电话">
                <van-field clearable clear-trigger="always" v-model="submitData.mobile" placeholder="请输入" :readonly="!edit"/>
            </van-cell>
            <van-cell title="药具名称">
                <van-field clearable clear-trigger="always" v-model="submitData.acyeterionName" placeholder="请输入" :readonly="!edit"/>
            </van-cell>
            <van-cell title="数量">
                <van-field clearable clear-trigger="always" v-model="submitData.acyeterionCount" placeholder="请输入" type="digit" :readonly="!edit"/>
            </van-cell>
            <van-cell title="批号">
                <van-field clearable clear-trigger="always" v-model="submitData.batchNumber" placeholder="请输入" type="digit" :readonly="!edit"/>
            </van-cell>
            <van-cell title="发放时间" :value="selectMsg.acyeterionTime" @click="acyeterionTimeShow = edit"
                        :value-class="{'value-common':selectMsg.acyeterionTime==='请选择'}" :is-link="edit" />
<!--                <van-cell title="链接">-->
<!--                    <van-field v-model="submitData.url" placeholder="请输入" :readonly="!edit"/>-->
<!--                </van-cell>-->
            <van-cell title="随访记录">
                <van-field v-model="submitData.des" placeholder="请输入随访记录"  rows="2"
                            autosize clearable clear-trigger="always"
                            type="textarea" :readonly="!edit"/>
            </van-cell>
<!--                <van-row v-if="edit||fileList.length!==0">-->
<!--                    <van-col :span="24">-->
<!--                        <van-uploader v-model="fileList"  @delete="uploaddel" :after-read="uploadRead" :deletable="edit" :show-upload="edit"/>-->
<!--                    </van-col>-->
<!--                </van-row>-->
        </van-cell-group>
        <van-row class="btns">
            <van-col :span="24" v-if="edit">
                <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
            </van-col>
            <van-col :span="24" v-else>
                <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
            </van-col>
        </van-row>
        <place-list v-if="placeListVisible" @checkPlace="checkPlace"></place-list>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import {getDictTree} from "@/utils/common";
import placeList from "../visit/placeList"
export default {
    data () {
        return {
            i: '',
            edit: false,
            file:{},
            detailData: {},
            fieldNames: {
                text: 'label',
                value: 'value',
                children: 'children',
            },
            loading: false,
            placeListVisible: false,
            acyeterionTimeShow: false,
            subareaShow: false,
            problemShow: true,
            problemSubmit: false,
            problemTypeShow: false,
            selectMsg: {
                addrTypeStr: '请选择',
                subareaStr: '请选择',
                acyeterionTime: '请选择'
            },
            submitData: {
                id : '',
                //领取人姓名
                name:'',
                //小区
                subarea:'',
                //联系电话
                mobile:'',
                //药具名称
                acyeterionName:'',
                //数量
                acyeterionCount:'',
                //批号
                batchNumber:'',
                //发放日期
                acyeterionTime:'',
                //随访记录
                des:'',
                fileList: [],
                newFileMessages: [],
                deleteFiles:[]
            },
            selectData: {
                subareaList: [],
                propagandaTypeList: [],
                propagandaLevelList: []
            },
            fileList: [],
        }
    },
    components :{
        topBar,
        placeList
    },
    methods: {
        // 删除图片
        // uploaddel (file, detail) {
        //     let i = detail.index
        //     this.submitData.newFileMessages.splice(i, 1)
        //     if (file.id !== 0) {
        //         this.submitData.deleteFiles.push(file.id)
        //     }
        // },
        // 上传图片
        // uploadRead (file) {
        //     this.$toast.loading({
        //         message: '上传中...',
        //         forbidClick: true,
        //         duration:0,
        //         overlay: true
        //     })
        //     if (!Array.isArray(file)) {
        //         file = [file]
        //     }
        //     file.forEach((item, index2) => {
        //         let formdata = new FormData();
        //         formdata.append("file", file.file)
        //         formdata.append("path", 'files/inspectionPicture/temporary')
        //         this.$http({
        //             url: this.$http.adornUrl('/wxapp/file/upload'),
        //             method: 'post',
        //             data: formdata
        //         }).then(({data})=> {
        //             if (index2 === file.length - 1) {
        //                 this.$toast.clear()
        //             }
        //             if (data.code == 0) {
        //                 let fileMessage = data.fileMessage
        //                 fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
        //                 this.submitData.newFileMessages.push(fileMessage)
        //                 return true
        //             } else {
        //                 this.$toast.fail(data.msg)
        //             }
        //         })
        //     })
        // },
        // 删除图片
        // uploaddelProblem (file, detail) {
        //     let i = detail.index
        //     this.submitData.problemList[detail.name].files.splice(i, 1)
        //     this.submitData.problemList[detail.name].problemUrls = file.filter((n) => n.uid !== file.uid)
        //     this.submitData.problemList[detail.name].files = this.submitData.problemList[detail.name].files.filter((n) => n.newFileName !== file.fileMessage.newFileName)
        // },
        // 上传图片
        // uploadReadProblem (file, detail) {
        //     this.$toast.loading({
        //         message: '上传中...',
        //         forbidClick: true,
        //         duration:0,
        //         overlay: true
        //     })
        //     if (!Array.isArray(file)) {
        //         file = [file]
        //     }
        //     file.forEach((item, index2) => {
        //         let formdata = new FormData();
        //         formdata.append("file", item.file)
        //         formdata.append("path", 'files/problem/temporary')
        //         this.$http({
        //             url: this.$http.adornUrl('/wxapp/file/upload'),
        //             method: 'post',
        //             data: formdata
        //         }).then(({data})=> {
        //             if (index2 === file.length - 1) {
        //                 this.$toast.clear()
        //             }
        //             if (data.code == 0) {
        //                 let fileMessage = data.fileMessage
        //                 fileMessage['uid'] = this.submitData.problemList[detail.name].files.length != 0 ? this.submitData.problemList[detail.name].files.length : 0
        //                 this.submitData.problemList[detail.name].files.push(fileMessage)
        //                 this.submitData.problemList[detail.name].problemUrls.push(fileMessage)
        //                 return true
        //             } else {
        //                 this.$toast.fail(data.msg)
        //             }
        //         })
        //     })
        // },
        // 初始化

        init () {
            this.getSubareaList()
            if (this.submitData.id) {
                this.edit = false
                this.getInfo()
            } else {
                this.edit =  true
            }
        },
        // 获取小区列表
        getSubareaList () {
            this.$http({
                url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
                method: 'post',
                params: this.$http.adornParams({
                    communityId: this.$orgId
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.selectData.subareaList = data.subAreaList
                } else {
                    this.$toast.fail(data.msg)
                }
            })
        },
        // 获取详情
        getInfo () {
            this.$http({
                url: this.$http.adornUrl('/wxapp/acyeterion/info/'+this.submitData.id),
                method: 'post'
            }).then(({data})=> {
                if (data && data.code === 0) {
                    this.submitData.name = data.appAcyeterion.name
                    this.submitData.mobile = data.appAcyeterion.mobile
                    this.selectMsg.subareaStr = data.appAcyeterion.subareaStr
                    this.submitData.subarea = data.appAcyeterion.subarea
                    this.submitData.acyeterionName = data.appAcyeterion.acyeterionName
                    this.submitData.acyeterionCount = data.appAcyeterion.acyeterionCount
                    this.submitData.batchNumber = data.appAcyeterion.batchNumber
                    this.selectMsg.acyeterionTime = data.appAcyeterion.acyeterionTime
                    this.submitData.acyeterionTime = data.appAcyeterion.acyeterionTime
                    this.submitData.des = data.appAcyeterion.des
                    let files = data.appAcyeterion.filesPath
                    let urls = []
                    for (let i in files) {
                        let file = files[i]
                        let u
                        u = getImageStream(files[i].filePath)
                        let url = {
                            uid: urls.length,
                            id: file.id,
                            status: 'done',//uploading上传中，done上传完成，error上传失败
                            fileType: file.type,
                            url: u,
                            realUrl: file.filePath.replace(/\\/g, "/")
                        }
                        urls.push(url);
                    }
                    this.fileList = urls
                } else {
                    this.$toast.fail(data.msg)
                }
            })
        },
        // 提交
        submit () {
            if (this.submitData.name === '') {return this.$toast.fail('失败:请输入领取人姓名');}
            if (this.submitData.subarea === '') {return this.$toast.fail('失败:请选择小区');}
            if (this.submitData.mobile === '') {return this.$toast.fail('失败:请输入联系电话');}
            if (this.submitData.acyeterionName === '') {return this.$toast.fail('失败:请输入药具名称');}
            if (this.submitData.acyeterionCount === '') {return this.$toast.fail('失败:请输入数量');}
            if (this.submitData.batchNumber === '') {return this.$toast.fail('失败:请输入批号');}
            if (this.submitData.acyeterionTime === '') {return this.$toast.fail('失败:请输入发放日期');}
            this.submitData.content = this.selectMsg.description
            this.loading = true
            this.$http({
                url: this.$http.adornUrl(`/wxapp/acyeterion/saveOrUpdate`),
                method: 'post',
                data : {
                    id: this.submitData.id,
                    //领取人姓名
                    name: this.submitData.name,
                    //联系电话
                    mobile: this.submitData.mobile,
                    //药具名称
                    acyeterionName: this.submitData.acyeterionName,
                    //数量
                    acyeterionCount: this.submitData.acyeterionCount,
                    //批号
                    batchNumber: this.submitData.batchNumber,
                    //发放日期
                    acyeterionTime: this.submitData.acyeterionTime,
                    //随访记录
                    des: this.submitData.des,
                    //巡查图片
                    fileList: this.submitData.fileList,
                    //修改图片组
                    newFileMessages: this.submitData.newFileMessages,
                    //删除图片的id
                    deleteFiles: this.submitData.deleteFiles,
                    subarea:this.submitData.subarea,
                }
            }).then(({data})=> {
                if (data.code == 0) {
                    this.$toast.success ({
                        message: `${this.submitData.id?'修改成功':'新增成功'}`,
                        duration: 3000,
                        onOpened: () => {
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 1500)
                        }
                    })
                } else {
                    this.loading = false
                    this.$toast.fail(data.msg);
                }
            })
            this.loading = false
        },
        // 所在小区选择
        subareaConfirm (value) {
            if (value) {
                this.selectMsg.subareaStr = value.name
                this.submitData.subarea = value.id
            }
            this.subareaShow = false
        },
        // 日期选项格式化
        formatDate (type, val) {
            if (type === 'year') {
                return val + '年'
            }
            if (type === 'month') {
                return val + '月'
            }
            if (type === 'day') {
                return val + '日'
            }
            if (type === 'hour') {
                return val + '时'
            }
            if (type === 'minute') {
                return val + '分'
            }
            return val
        },
        acyeterionTimeConfirm (value) {
            if (value) {
                this.selectMsg.acyeterionTime = formatterDateTimeWithOutSec(value)
                this.submitData.acyeterionTime = formatterDateTimeWithOutSec(value)
            }
            this.acyeterionTimeShow = false
        },
    },
    created () {
        this.submitData.id = this.$route.query.id || ''
        document.querySelector('html').style.backgroundColor = '#fff'
        this.submitData.orgId = this.$orgId
        this.submitData.userId = this.$globalData.userInfo.userId
        this.init()
    },
    beforeDestroy () {
        document.querySelector('html').style.backgroundColor = ''
    }
}
</script>
